export const tabsCollections = [
    { type: "price", label: "Цена", title: "Фильтры по цене" },
    { type: "bodyType", label: "Кузов", title: "Фильтр по кузову" }
];

export const priceCollections = [
    {
        id: 1,
        img: "/img/car-by-price/price-collection-1.webp",
        name: "до 300 000 ₽",
        link: "/catalog/all-cities/all?price_to=300000"
    },
    {
        id: 2,
        img: "/img/car-by-price/price-collection-2.webp",
        name: "до 500 000 ₽",
        link: "/catalog/all-cities/all?price_from=300000&price_to=500000"
    },
    {
        id: 3,
        img: "/img/car-by-price/price-collection-3.webp",
        name: "до 1 500 000 ₽",
        link: "/catalog/all-cities/all?price_from=500000&price_to=1500000"
    },
    {
        id: 4,
        img: "/img/car-by-price/price-collection-4.webp",
        name: "от 3 000 000 ₽",
        link: "/catalog/all-cities/all?price_from=3000000"
    }
];

export const bodyTypeCollections = [
    {
        id: 1,
        img: "/img/car-body-type/sedan.webp",
        name: "Седан",
        link: "/catalog/all-cities/all?body_type=седан"
    },
    {
        id: 2,
        img: "/img/car-body-type/hatchback.webp",
        name: "Хэтчбэк",
        link: "/catalog/all-cities/all?body_type=хэтчбек"
    },
    {
        id: 3,
        img: "/img/car-body-type/vnedorozhnik.webp",
        name: "Внедорожник",
        link: "/catalog/all-cities/all?body_type=внедорожник"
    },
    {
        id: 4,
        img: "/img/car-body-type/universal.webp",
        name: "Универсал",
        link: "/catalog/all-cities/all?body_type=универсал"
    },
    {
        id: 5,
        img: "/img/car-body-type/liftback.webp",
        name: "Лифтбэк",
        link: "/catalog/all-cities/all?body_type=лифтбек"
    },
    {
        id: 6,
        img: "/img/car-body-type/coupe.webp",
        name: "Купе",
        link: "/catalog/all-cities/all?body_type=купе"
    },
    {
        id: 7,
        img: "/img/car-body-type/minivan.webp",
        name: "Минивэн",
        link: "/catalog/all-cities/all?body_type=минивэн / микроавтобус"
    },
    {
        id: 8,
        img: "/img/car-body-type/cabriolet.webp",
        name: "Кабриолет",
        link: "/catalog/all-cities/all?body_type=кабриолет"
    },
    {
        id: 9,
        img: "/img/car-body-type/pickup.webp",
        name: "Пикап",
        link: "/catalog/all-cities/all?body_type=пикап"
    }
];