import React, { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import classNames from "classnames";
import { LeftIcon } from "@shared/ui/Icon/ui/Common/LeftIcon";
import { RightIcon } from "@shared/ui/Icon/ui/Common/RightIcon";
import { FilterCard } from "./FilterCard";
import { bodyTypeCollections, priceCollections, tabsCollections } from "./config";
import { CollectionType } from "./types";
import classes from "./CategoryFilter.module.scss";

export const CategoryFilter = () => {
    const [activeCollection, setActiveCollection] = useState<CollectionType>("price");
    const currentCollection = activeCollection === "price" ? priceCollections : bodyTypeCollections;
    const showArrows = currentCollection.length > 4;

    const [sliderRef, instanceRef] = useKeenSlider({
        slides: {
            perView: 4,
            spacing: 16
        },
        breakpoints: {
            "(max-width: 1024px)": {
                slides: {
                    perView: 3.5,
                    spacing: 16
                }
            },
            "(max-width: 600px)": {
                slides: {
                    perView: 2.5,
                    spacing: 16
                }
            }
        }
    });

    const handleTabClick = (type: CollectionType) => () => {
        setActiveCollection(type);
    };

    const handlePrevClick = () => {
        instanceRef.current?.prev();
    };

    const handleNextClick = () => {
        instanceRef.current?.next();
    };

    useEffect(() => {
        if (instanceRef.current) {
            instanceRef.current.update();
        }
    }, [activeCollection, instanceRef]);

    return (
        <div className={classes["category-filter"]}>
            <p className={classes["category-filter__title"]}>Популярные подборки автомобилей</p>
            <div className={classes["category-filter__top"]}>
                <div className={classes["category-filter__switcher"]}>
                    {tabsCollections.map((tab) => (
                        <button
                            key={tab.type}
                            title={tab.title}
                            className={classNames(
                                classes["category-filter__button"],
                                activeCollection === tab.type &&
                                    classes["category-filter__button--active"]
                            )}
                            onClick={handleTabClick(tab.type as CollectionType)}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
                {showArrows && (
                    <div className={classes["category-filter__arrows"]}>
                        <div
                            className={classes["category-filter__arrow"]}
                            aria-label="Предыдущая подборка"
                            onClick={handlePrevClick}
                        >
                            <LeftIcon className={classes["category-filter__arrow--color"]} />
                        </div>
                        <div
                            className={classes["category-filter__arrow"]}
                            aria-label="Следующая подборка"
                            onClick={handleNextClick}
                        >
                            <RightIcon className={classes["category-filter__arrow--color"]} />
                        </div>
                    </div>
                )}
            </div>
            <div
                ref={sliderRef}
                className={classNames("keen-slider", classes["category-filter__slider"])}
            >
                {currentCollection.map((card) => (
                    <FilterCard key={card.id} img={card.img} name={card.name} link={card.link} />
                ))}
            </div>
        </div>
    );
};